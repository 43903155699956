import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscribers module configurations
export const LISTING_COLUMNS = [
  {
    id: 'deviceId',
    label: 'Device UID',
    width: '15%',
    align: 'left',
    dataKey: 'deviceId',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    width: '10%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryCode',
    label: 'Location',
    width: '20%',
    align: 'left',
    dataKey: 'countryCode',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    width: '15%',
    align: 'left',
    dataKey: 'signupTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Platform',
    width: '10%',
    align: 'left',
    dataKey: 'signupFromPlatform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Watched Time',
    width: '10%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'deviceId',
    label: 'Device UID',
    dataKey: 'deviceId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryCode',
    label: 'Location',
    dataKey: 'countryCode',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'watchedSeconds',
    label: 'Total Watched',
    dataKey: 'totalSecondsWatch',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    dataKey: 'signupTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Platform',
    dataKey: 'signupFromPlatform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
];
