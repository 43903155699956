import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ReportsAppSessionsView from '../views/reports/app-sessions.view';
import ReportsWatchSessionsView from '../views/reports/watch-sessions.view';
import {
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_APP_SESSIONS,
  SHOW_REPORTS_CONTENT_CREATOR,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_WATCH_SESSIONS,
} from '../config/const.config';
import { checkAdminUserRole } from '../utils/local-cache.util';
import ReportsVideoWatchSessionsView from '../views/reports/video-watch-session.view';
import ReportsContentCreatorView from '../views/reports/content-creator.view';
import SubscribersView from '../views/subscribers/subscribers.view';

const AppRouter = ({ isLoggedIn }) => {
  const routeMappings = [];

  const adminUserRole = checkAdminUserRole();

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: (
        <Navigate
          to={
            adminUserRole === 'ADMIN'
              ? ROUTE_PATH.DASHBOARD
              : ROUTE_PATH.CONTENT_VIDEOS
          }
        />
      ),
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView adminUserRole={adminUserRole} />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }
    routeMappings.push({
      path: ROUTE_PATH.SUBSCRIBERS,
      element: <SubscribersView />,
      exact: true,
    });

    if (SHOW_REPORTS_OVERVIEW_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_WATCH_SESSIONS,
        element: <ReportsWatchSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_VIDEO_WATCH_SESSIONS,
        element: <ReportsVideoWatchSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_APP_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_APP_SESSIONS,
        element: <ReportsAppSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_CONTENT_CREATOR) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_CONTENT_CREATOR,
        element: <ReportsContentCreatorView />,
        exact: true,
      });
    }
    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? (
      <PageNotFoundView adminUserRole={adminUserRole} />
    ) : (
      <LoginView />
    ),
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  categoriesTab: PropTypes.array.isRequired,
  rerenderComponent: PropTypes.func.isRequired,
};

export default AppRouter;
