import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// import { Link } from 'react-router-dom';
import AuthLayout from '../../layout/auth.layout';
import useResponsive from '../../hooks/responsive.hook';
import Logo from '../../components/common/logo.component';
import Login from '../../components/auth/login.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import { APP_BACKGROUND } from '../../theme/theme-colors';
import GradientOverlay from '../../components/auth/gradient.component';

const ImageBackground = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: APP_BACKGROUND,
}));

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const StyledSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 480,
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 2),
  borderRadius: theme.spacing(1),
  margin: 'auto',
  marginTop: 30,
  maxWidth: 500,
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const LoginView = () => {
  const pageTitle = PAGE_TITLE_LIST.LOGIN;
  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');

  return (
    <AuthLayout pageTitle={pageTitle}>
      <ImageBackground>
        <GradientOverlay />
        <StyledRoot>
          {mdUp && (
            <StyledSection>
              <Logo
                sx={{
                  width: 300,
                  margin: '20px auto',
                  cursor: 'default',
                  zIndex: 1,
                }}
              />
            </StyledSection>
          )}

          <Container
            maxWidth="sm"
            style={{
              zIndex: 1,
            }}
          >
            <StyledContent>
              {mdDown && (
                <Logo
                  sx={{
                    width: 200,
                    margin: '20px auto',
                    cursor: 'default',
                    zIndex: 1,
                  }}
                />
              )}

              <Typography variant="h4" align="center">
                CMS
              </Typography>

              <Login />
            </StyledContent>
          </Container>
        </StyledRoot>
      </ImageBackground>
    </AuthLayout>
  );
};

export default LoginView;
