import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CountWidget from './count-widget.component';
import { getCounters } from '../../services/reports.service';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SUBSCRIBERS,
} from '../../config/const.config';
import ROUTE_PATH from '../../config/routes.config';

const Counters = () => {
  const [counters, setCounters] = useState({
    channels: 0,
    videos: 0,
    ads: 0,
    sessions: 0,
    subscribers: 0,
    // categories: 0,
  });

  useEffect(() => {
    getCounters()
      .then((res) => {
        setCounters({
          ...counters,
          channels: res?.data?.channels || 0,
          videos: res?.data?.videos || 0,
          ads: res?.data?.ads || 0,
          sessions: res?.data?.sessions || 0,
          subscribers: res?.data?.subscribers || 0,
          // categories: res?.data?.categories || 0,
        });
      })
      .catch(() => {
        // ignore
      });
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      {/* {!DEFAULT_CHANNEL && (
        <Grid item xs={12} sm={3} md={3}>
          <CountWidget
            title="Channels"
            total={counters.channels}
            icon="ic:twotone-checklist"
            color="warning"
            navLink={ROUTE_PATH.CONTENT_CHANNELS}
          />
        </Grid>
      )} */}

      {SHOW_SUBSCRIBERS && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 3 : 4}
          md={!DEFAULT_CHANNEL ? 3 : 4}
        >
          <CountWidget
            title="Subscribers"
            total={counters.subscribers}
            color="error"
            icon="ci:users"
            navLink={ROUTE_PATH.SUBSCRIBERS}
          />
        </Grid>
      )}

      {SHOW_CONTENT_CATEGORIES && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 3 : 4}
          md={!DEFAULT_CHANNEL ? 3 : 4}
        >
          <CountWidget
            title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
            total={counters.categories}
            color="info"
            icon="iconamoon:category"
            navLink={ROUTE_PATH.CONTENT_CATEGORIES}
          />
        </Grid>
      )}

      {SHOW_CONTENT_VIDEOS && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 3 : 4}
          md={!DEFAULT_CHANNEL ? 3 : 4}
        >
          <CountWidget
            title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
            total={counters.videos}
            color="success"
            icon="octicon:video-16"
            navLink={ROUTE_PATH.REPORTS_VIDEO_WATCH_SESSIONS}
          />
        </Grid>
      )}

      {SHOW_REPORTS_WATCH_SESSIONS && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 3 : 4}
          md={!DEFAULT_CHANNEL ? 3 : 4}
        >
          <CountWidget
            title="Watch Sessions"
            total={counters.sessions}
            color="error"
            icon="ic:outline-list-alt"
            navLink={ROUTE_PATH.REPORTS_WATCH_SESSIONS}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Counters;
