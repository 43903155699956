import CELL_DATA_TYPES from '../cell-types.config';
// import etfLogo from '../../assets/images/logo/etf_small_logo.png';

// Realtime live users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'TOTAL_COUNT',
    label: 'Total',
    align: 'left',
    dataKey: 'TOTAL_COUNT',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'ROKU_COUNT',
    label: 'Roku',
    align: 'center',
    dataKey: 'ROKU_COUNT',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
    color: 'primary',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Roku_logo.svg/300px-Roku_logo.svg.png',
  },
  {
    id: 'APPLETV_COUNT',
    label: 'Apple TV',
    align: 'center',
    dataKey: 'APPLETV_COUNT',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
    color: 'error',
    icon: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Apple_TV_%28logo%29.svg/130px-Apple_TV_%28logo%29.svg.png',
  },
  {
    id: 'FIRETV_COUNT',
    label: 'Fire TV',
    align: 'center',
    dataKey: 'FIRETV_COUNT',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
    color: 'primary',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Amazon_Fire_TV_logo_%28New%29.png/180px-Amazon_Fire_TV_logo_%28New%29.png',
  },
  // {
  //   id: 'ANDROIDTV_COUNT',
  //   label: 'Android TV',
  //   align: 'center',
  //   dataKey: 'ANDROIDTV_COUNT',
  //   sortable: false,
  //   type: CELL_DATA_TYPES.NUMBER,
  //   color: 'error',
  //   icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/New_Android_TV_logo_%28redesign%29.svg/375px-New_Android_TV_logo_%28redesign%29.svg.png',
  // },
  // {
  //   id: 'WEB_COUNT',
  //   label: 'Web',
  //   align: 'center',
  //   dataKey: 'WEB_COUNT',
  //   sortable: false,
  //   type: CELL_DATA_TYPES.NUMBER,
  //   color: 'primary',
  //   icon: etfLogo,
  // },
];

export const LISTING_ACTIONS = [];
