import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import CellValue from './cell-value.component';

const ViewData = ({ columns, row }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {columns.map((c) => (
          <>
            <TableRow />
            <TableRow key={`col-${c.id}`}>
              <TableCell
                width="25%"
                variant="head"
                sx={{ border: 0, verticalAlign: 'top' }}
              >
                {c.label}
              </TableCell>
              <TableCell
                width="75%"
                variant="body"
                sx={{ border: 0, verticalAlign: 'top' }}
              >
                {c.id === 'countryCode' && row[c.dataKey] !== '' && (
                  <Box
                    sx={{
                      display: 'inline',
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={row.countryCode}
                      title={row.countryName}
                      style={{
                        fontSize: '1.3em',
                      }}
                    />
                  </Box>
                )}{' '}
                <CellValue
                  type={c.type}
                  value={
                    c.id === 'countryCode'
                      ? `${row.countryName}`
                      : row[c.dataKey]
                  }
                />
                {c.secondaryFields && (
                  <>
                    {c.secondaryFields.map((sf, sfIdx) => {
                      if (row[sf.dataKey])
                        return (
                          <React.Fragment key={`sf-data-col-${c.id}-${sfIdx}`}>
                            <br />
                            <CellValue
                              type={sf.type}
                              value={row[sf.dataKey]}
                              color="secondary"
                              dataKey={sf.dataKey}
                            />
                          </React.Fragment>
                        );
                      return null;
                    })}
                  </>
                )}
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

ViewData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  row: PropTypes.shape().isRequired,
};

export default ViewData;
