import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { getPlatformLabel } from '../../config/platforms.config';

const PlatformLabel = ({ value, color }) => (
  <Typography variant="body2" component="span" color={color}>
    {getPlatformLabel(value)}
  </Typography>
);

PlatformLabel.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PlatformLabel;
