import React from 'react';
import { Box } from '@mui/material';

const GradientOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  >
    <Box
      component="section"
      sx={{
        position: 'relative',
        backgroundColor: 'transparent',
        backgroundImage: `linear-gradient(130deg, #37517E 0%, #03368F 89%)`,
        transition: `background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s`,
        height: '30em',
      }}
    >
      {/* Background Overlay */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          backgroundColor: '#37517E',
          backgroundImage: `url('http://www.etfguide.com/wp-content/uploads/2023/01/Post-BG.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: '0.15',
          transition: `background 0.3s, border-radius 0.3s, opacity 0.3s`,
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
        }}
      />

      {/* Shape Divider */}
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          left: 0,
          width: '100%',
          lineHeight: 0,
          direction: 'ltr',
          bottom: -1,
          transform: `rotate(180deg)`,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          style={{
            width: `calc(260% + 1.3px)`,
            height: '120px',
            transform: `translateX(-50%) rotateY(180deg)`,
            position: 'relative',
            left: '50%',
          }}
        >
          <path
            fill="#37507E"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
              c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
              c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          />
        </svg>
      </Box>

      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      />
    </Box>
  </Box>
);

export default GradientOverlay;
