// Define platforms list
const PLATFORMS_LIST = [
  {
    id: 'ROKU',
    title: 'Roku',
  },
  {
    id: 'APPLETV',
    title: 'Apple TV',
  },
  {
    id: 'FIRETV',
    title: 'Fire TV',
  },
];

export const getPlatformLabel = (val) => {
  let label = '';

  PLATFORMS_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const CHART_COLORS = {
  ROKU: '#6F1AB1',
  APPLETV: '#4CD0F1',
  FIRETV: '#FC4C02',
};

export default PLATFORMS_LIST;
