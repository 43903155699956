import { CONTENT_ALIAS } from './const.config';

// Define page-title configurations
const PAGE_TITLE_LIST = {
  OVERVIEW_DASHBOARD: 'Overview Dashboard',
  REALTIME_DASHBOARD: 'Live Users (Per Platform)',
  SUBSCRIBERS: 'Subscribers',
  ADMIN_USERS: 'CMS Users',
  EPG: 'Program Guide (EPG) Setup',
  CONTENT_CHANNELS: 'Content / Channels',
  CONTENT_CATEGORY: `Content / ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  CONTENT_VIDEOS: `Content / ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_ADS: 'Content / Ads',
  CONTENT_SEQUENCE: 'Content / Sequence',
  CONTENT_TABS: 'Content / Tabs',
  REPORTS_OVERVIEW_VIDEOS: `Analytics Reports / Overview (${CONTENT_ALIAS.VIDEO_PLURAL})`,
  REPORTS_OVERVIEW_ADS: 'Analytics Reports / Overview (Ads)',
  REPORTS_LIVE_USERS: 'Analytics Reports / Live Users',
  REPORTS_WATCH_SESSIONS: 'Analytics Reports / Watch Sessions',
  REPORTS_VIDEO_WATCH_SESSIONS: 'Analytics Reports / Videos',
  REPORTS_CONTENT_CREATOR: 'Analytics Reports / Content Creator',
  REPORTS_APP_SESSIONS: 'Analytics Reports / App Sessions',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  ERROR_NOT_FOUND: '404 Not Found',
};

export default PAGE_TITLE_LIST;
