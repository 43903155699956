import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Watch sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    width: '15%',
    align: 'left',
    dataKey: 'sessionId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '15%',
    align: 'left',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'deviceUid',
    label: 'Device UID',
    width: '20%',
    align: 'left',
    dataKey: 'deviceUid',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    width: '20%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryCode',
    label: 'Location',
    width: '20%',
    align: 'left',
    dataKey: 'countryCode',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    width: '20%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_APP_SESSIONS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    dataKey: 'sessionId',
    type: CELL_DATA_TYPES.TEXT,
  },
  // {
  //   id: 'channelName',
  //   label: 'Channel',
  //   dataKey: 'channelName',
  //   type: CELL_DATA_TYPES.TEXT,
  // },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'deviceUid',
    label: 'Device UID',
    dataKey: 'deviceUid',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryCode',
    label: 'Location',
    dataKey: 'countryCode',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    dataKey: 'sessionStartedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },

  {
    id: 'sessionEndedAt',
    label: 'Session End Time',
    dataKey: 'sessionEndedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
